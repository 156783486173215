.places-item {
    padding: 20px;
    margin: 5px;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(140,152,193,0.75);
    -moz-box-shadow: 0px 0px 10px 2px rgba(140,152,193,0.75);
    box-shadow: 0px 0px 10px 2px rgba(140,152,193,0.75);
}

@media (min-width: 768px) {
    .places-item {
        padding: 30px;
        margin: 10px;
    }
}

.places-title {
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
}

.places-address {
    justify-content: center;
}

.places-team-logo {
    width: 100px;
    aspect-ratio: 1 auto;
}

.places-logo-container {
    justify-content: center;
}
