.link-click{
  text-decoration: none;
  color:inherit;
}

.link-click:hover{
  text-decoration: none;
  color:inherit;
}

.division-team-logo {
    height: 30px;
    width: 30px;
}

.division-row-logos {
    margin-bottom: 10px;
}

.division-row {
    padding: 10px;
    border-radius: 5px;
    background-color: #8c98c110;
    border: 1px solid var(--primary);
    margin-top: 5px;
    font-size: 12px;
}

.division-container-header {
    top: 83px;
    position: sticky;
    background-color: #fbfbfc;
    z-index: 1;
    padding-top: 10px;
}

.division-container-header-watch {
    background-color: #fbfbfc;
    z-index: 1;
    padding-top: 10px;
}