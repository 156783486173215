.title-div {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
}

.title {
    font-size: 30px;
}

.team-col {
   text-align: center;
   cursor: pointer;
}
