.result-info-row {
    padding: 5px;
    border-radius: 5px;
    background-color: var(--background-text);
    margin-top: 5px;
    font-size: 12px;
    color: white
}

.results-container-header {
    top: 83px;
    position: sticky;
    background-color: #fbfbfc;
    z-index: 1;
    padding-top: 10px;
    padding-bottom: 10px;
}

.result-team-row{
  align-items: center;
}

.result-team-row-watch {
    align-items: center;
    font-weight: 700;
    font-size: 18px;
}

.result-team-logo{
  height: 40px;
  width: 40px;
  margin-right: 5px;
}

.result-team-logo-watch {
    height: 80px;
    width: 80px;
    margin-right: 5px;
}

.result-score {
  font-size: 20px;
  text-align: right;
}

.result-score-watch {
    font-size: 22px;
    text-align: right;
    font-weight: 700;
}

.result-matches-container {
  margin-bottom: 40px;
}

.noresult-matches-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}

.noresults-image
{
  width: calc(100dvh / 4);
  border-radius: 5px;
}

.link-click{
  text-decoration: none;
  color:inherit;
}

.link-click:hover{
  text-decoration: none;
  color:inherit;
}

.result-choose-team{
    display: flex;
    justify-content: right;
}