.division-container {
    margin-bottom: 40px;
}

.division-section h3 {
    margin-top: 20px;
}

.calendar-table-head {
    padding: 5px;
    border-radius: 5px;
    border: 2px solid var(--background-text);
    margin-top: 20px;
    font-size: 12px;
}
