/* Keep navbar inline */
:root {
    --primary: #8c98c1;
    --primary-text: #374C7C;
    --background-text: #485684;
}

h2 {
    font-size: 18px;
}

@media (min-width: 576px) {
    #root > div > header > nav > div > div
  {
    display:flex;
  } 
}

@media (max-width: 575px) {
  #root > div > header > nav > div > div
  {
    display:contents;
  } 
  .navbar-toggler:focus
  {
    box-shadow: none;
  }
  .navbar-collapse
  {
    text-align: center;
  }
}


a {
  color: var(--primary-text);
}

.nav-link
{
  color: var(--primary-text);
  cursor: pointer;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: var(--primary);
  border-color: #1861ac;
}
.accordion-button:not(.collapsed)
{
  color: var(--primary);
}
.row-odd {
    background-color: #8c98c133;
}

.breadcrumb-item.active
{
  cursor: pointer;
  color: var(--primary);
}

.share-link {
    cursor: pointer;
    color: var(--primary-text);
}