.products-title-label {
    padding-bottom:10px;
}

.product-tab-content {
    margin-top: 30px;
}

.products-tab-pane-container {
    margin-top: 30px;
}

.products-info {
    text-align: right;
}

    .products-info button {
        text-decoration: none;
        color: var(--primary-text);
    }

.rce-input-textarea {
    height: 42px !important;
}