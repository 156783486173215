.avatar-item {
    display: grid;
    max-width: 100px;
    font-size: 10px;
    gap:8px;
}

.avatar-image {
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.75);
    position: relative;
}
.players-row-selector .avatar-image:hover {
    transform: scale(1.3);
}

.avatar-image-hidden {
    display: none;
}
