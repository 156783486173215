.players-row-selector {
    display: flex;
    gap: 15px;
    padding: 30px;
    overflow-y: hidden;
    font-size: 10px;
}

/* hide scrollbar but allow scrolling */
.players-row-selector {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
}

.players-row-selector::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
.save-container {
    display: flex;
    margin-top: 20px;
    justify-content: center;
}

.save-container .alert {
    width: 250px;
}

.submit-button {
    background-color: var(--background-text);
    width: 200px;
}