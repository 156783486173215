.related-matches-container {
    max-width: 600px;
    background-color: #8c98c133;
    border-radius: 10px;
}

.related-matches-team-name {
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
}
.related-matches-status {
    display: block;
    text-align: center;
    font-size: 12px;
}
.related-matches-team-row {
    align-items: center;
    text-align: -webkit-center;
}

.related-matches-team-logo {
    height: 30px;
    width: 30px;
    margin-right: 5px;
}

.related-matches-score {
    font-size: 18px;
    text-align: center;
}

.link-click {
    text-decoration: none;
    color: inherit;
}

    .link-click:hover {
        text-decoration: none;
        color: inherit;
    }

.slick-dots li button:before {
    opacity: 1;
    color: rgba(0, 0, 0, 0.25);
}

.slick-dots li.slick-active button:before {
    color: #000;
}

.slick-dots {
    display: contents !important;
}