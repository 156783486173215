.wolf-background {
    position: fixed;
    width: 100%;
    top: 0;
    opacity: 0.05;
    border-radius: 5%;
    z-index: -1;
}

@media (max-width: 450px) {
    .wolf-background {
        margin-top: calc(40vw);
    }
}

@media (min-width: 900px) {
    .wolf-background {
        margin-top: calc(-20vw);
    }
}

.accordion .accordion-item{
    background-color: transparent;
}

h4 {
    font-size: 16px;
}