.regulation-main-container {
    height: 100%;
}

.regulation-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
}

.message-list {
    height: calc(100vh - 270px);
    overflow-y: hidden;
}

.regulation-download {
    display: flex;
    justify-content: right;
}

    .regulation-download a {
        align-self: end;
        color: var(--primary-text);
    }


@media (min-width: 792px) {
    .message-list {
        height: calc(100vh - 260px);
    }
}

.regulation-input {
    position: absolute;
    max-width: 900px;
    min-width: unset !important;
    bottom: 20px;
    width: 70%;
    border: 2px #8c98c1 solid;
    border-radius: 2px;
}

@media (max-width: 600px) {
    .regulation-input {
        width: 90%;
    }
}
