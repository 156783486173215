.product-container {
    padding: 20px;
    margin: 5px;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(140,152,193,0.75);
    -moz-box-shadow: 0px 0px 10px 2px rgba(140,152,193,0.75);
    box-shadow: 0px 0px 10px 2px rgba(140,152,193,0.75);
}

.product-image-container {
    margin-top: 10px;
    width: 100%;
    justify-content: center;
}

.product-image {
    width: 50%;
    aspect-ratio: 1 auto;
}

@media (min-width: 768px) {
    .product-container {
        padding: 30px;
        margin: 10px;
    }
    .product-image {
        width: 90%;
    }
}

.product-title {
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
}

.product-price {
    margin-top: 10px;
    font-weight: 800;
}

.product-delivery {
    font-size: 12px;
    justify-content: right;
}
