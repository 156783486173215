.nav-link.active {
    background-color: var(--background-text) !important;
}

.stats-header-row {
    background-color: var(--background-text);
    border-radius: 5px;
    padding: 2px;
    color: white;
}

.player-link-click{
  text-decoration: none;
  color:darkblue;
}

.row-odd {
    background-color: #8c98c133;
}