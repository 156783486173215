.selected-players-area {
    max-width: 500px;
    min-height: 180px;
    min-width: 300px;
    padding: 20px;
    background-color: var(--background-text);
    border-radius: 5px;
    justify-self: center;
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    color:white;
}
