.header {
    margin-bottom: 100px;
}

.navbar {
    background-color: white;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.logo-navbar
{
  border-radius: 20px;
  width: 60px;
  height: 60px;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
