.match-container-scorer {
    top: 86px;
    position: sticky;
    background-color: #fbfbfc;
    z-index: 1;
    padding-top: 10px;
}

@media (max-width: 767px) {
    .match-container-scorer {
        top: 84px;
    }
}

.action-row {
    align-items: center;
    padding-left: 10px;
}

.action-row div.row
{
  justify-content: center;
}

.stats-container
{
  margin-top: 10px;
}

.stats-team-row
{
  align-items: center;
}

.background-image
{
  opacity: 0.2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.actions-container
{
  margin-top: 20px;
  margin-bottom: 50px;
}

.result-status-team-row {
    text-align: center;
    background-color: red;
    padding: 10px;
    border-radius: 10px;
    margin-top: 20px;
    color: white;
    margin-bottom: 20px;
}

.result-status-team-row-watch {
    text-align: center;
    background-color: red;
    padding: 10px;
    border-radius: 10px;
    margin-top: 20px;
    color: white;
    margin-bottom: 20px;
    font-size: 20px;
}

.result-status-team-row.finished 
{
  text-align: center;
  background-color: green;
  padding: 10px;
  border-radius: 10px;
  color:white;
  margin-bottom: 20px;
}

.result-status-team-row-watch.finished {
    text-align: center;
    background-color: green;
    padding: 10px;
    border-radius: 10px;
    color: white;
    margin-bottom: 20px;
}

.nav-link.active {
    background-color: var(--background-text) !important;
}

.stats-header-row {
    background-color: var(--background-text);
    border-radius: 5px;
    padding: 2px;
    margin-top: 10px;
}

.player-link-click{
  text-decoration: none;
  color:darkblue;
}