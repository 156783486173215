.container-player
{
    padding: 20px;
}

.profile-image
{
    width: 250px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.75);
}
.profile-image-hidden
{
    display: none;
}

.team-image
{
    width: 70px;
    margin-right: 10px;
}

.player-category-name
{
    margin-top: 20px;
}

.team-line {
    display:flex;
    align-items: center;
}
    .team-line span:nth-child(2) {
        width: 100%;
    }

    .team-line span:nth-child(3) {
        text-align: right;
        width: 60%;
    }
