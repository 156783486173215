.container-classification
{
 margin-bottom: 30px;
}

.classification-team-name
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
